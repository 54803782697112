<template>
  <v-dialog :value="vModelDialog" class="dialog" max-width="600" persistent>
    <v-card class="card font-family-raleway-medium">
      <h3 class="text-center">Update campaign name</h3>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col md="12">
            <v-text-field
              class="border-16 mt-4"
              placeholder="Name"
              v-model="campaignName"
              height="60"
              :rules="nameRule"
              required
              outlined
            />
          </v-col>
          <v-col class="mb-4 text-center" cols="12">
            <v-btn
              class="mr-5 font-weight-bold"
              style="box-shadow: none; background-color: #ffffff"
              height="42"
              @click="$emit('cancelModal')"
            >
              cancel
            </v-btn>
            <v-btn
              class="button font-weight-bold px-5"
              @click="handleSaveCampaignName"
              :loading="updatingName"
              height="45"
              >save
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { campaignService } from "@/services/campaign";

export default {
  name: "UpdateCampaignNameModal",
  props: {
    vModelDialog: Boolean,
    campaignName: String,
  },
  data() {
    return {
      nameRule: [(v) => !!v || "Name is required"],
      valid: false,
      updatingName: false,
    };
  },
  methods: {
    async handleSaveCampaignName() {
      const { id } = this.$route.params;
      this.updatingName = true;
      try {
        await campaignService.updateCampaignName(id, this.campaignName);
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully updated campaign name!",
        });
      } catch (e) {
        console.log(e.response);
      }
      this.updatingName = false;
      this.$emit("cancelModal");
    },
  },
};
</script>

<style scoped></style>
